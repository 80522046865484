import $ from 'jquery';
import Cookies from 'js-cookie';
import ScrollMagic from 'scrollmagic';
import './form.js';
import './app.js';

window.jQuery = window.$ = $;
var controller = new ScrollMagic.Controller();

$(function() {
    $.fn.extend({
        toggleText: function(a, b){
            return this.text(this.text() == b ? a : b);
        }
    });

    var $root = $('html, body');

    // Set cookies
    $("#cookies-button").on("click", function() {
        Cookies.set('privacy-policy', true);
        $("#cookies-modal").hide();
    });

    if(Cookies.get('privacy-policy')) {
        $("#cookies-modal").hide();
    }
    
    // Convert navbar to solid background
    $(window).on("scroll", function() {
        // Make the navbar solid background after scrolling down the page
        if(($(window).width() < 768 && $(this).scrollTop() > 100) || ($(window).width() > 769 && $(this).scrollTop() > 250)) {
            // Only shift the navbar on mobile
            if(window.location.pathname.split(".")[0] === "/faqs" && $(window).width() < 768) {
                $("#mobile-nav").css("transform", "translateY(-90px)");
                $("aside").css("transform", "translateY(-90px)");
            }
            $("header").addClass("scrolled");
            $(".disclaimer").css("background", "#000101");
            $(".main-nav").css("background", "linear-gradient(90deg, #003940 0%, #006062 49.94%, #003840 99.45%)");
        } else {
            if(window.location.pathname.split(".")[0] === "/faqs" && $(window).width() < 768) {
                $("#mobile-nav").css("transform", "translateY(0px)");
                $("aside").css("transform", "translateY(0px)");
            }
            $("header").removeClass("scrolled");
            $(".disclaimer").css("background", "");
            $(".main-nav").css("background", "");
        }
    });

    // Generate sidebar
    $("div[data-sidebar]").each(function(){
        let navId = $(this).data("sidebar").replace(/\s+/g, '-').toLowerCase();
        var dataId = $(this).data("sidebar").replace(/\s+/g, '-').toLowerCase();
        $("aside ul").append("<li><a href="+"#"+ navId + " data-id="+ dataId + " class='nav-sidebar-link'>" + $(this).data("sidebar") + "&nbsp;<span>›</span></a></li>");
    }).promise().done( function() { 
        $("aside ul li").first().addClass("active");
    });

    let sidebar = $("aside ul li");
    // Generate sidebar mappings
    $("div[data-sidebar]").each(function(){
        var sideItem = $(this)[0];
        var dataId = $(this).data("sidebar").replace(/\s+/g, '-').toLowerCase();
        new ScrollMagic.Scene({triggerElement: sideItem, offset: -100})
            .on('enter', () => {
                $(sidebar).removeClass("active");
                $(sidebar).find("[data-id='" + dataId + "']").parent().addClass("active")
            }).addTo(controller);

        new ScrollMagic.Scene({triggerElement: sideItem, offset: 400})
            .on('leave', () => {
                $(sidebar).removeClass("active");
                $(sidebar).find("[data-id='" + dataId + "']").parent().addClass("active");
        }).addTo(controller);
    });

    sidebar.find("a").on("click", function() {
        if($(window).width() < 768) {
            $("aside").toggle();
            $(".overlay").toggle();
            $("#mobile-nav").removeClass("opened");
        }
    });
    
    // Generate accordion 
    $(".accordion").each(function() {
        $(this).prepend("<span class='toggle'>Expand all</span>");
    });

    // Expand all toggle items in a seciton
    $(".toggle").on("click", function() {
        var parent = $(this).parent();
        if($(this).text() === "Collapse all") {
            parent.find(".item .answer").hide();
            parent.find(".toggle-item").each(function() {
                $(this).removeClass("rotate");
            });
            $(this).text("Expand all");
        } else {
            parent.find(".item .answer").show();
            parent.find(".toggle-item").each(function() {
                $(this).addClass("rotate");
            });
            $(this).text("Collapse all");
        }
    });

    // Generate toggle button per item
    $(".accordion .item h3").each(function() {
        $(this).prepend("<img class='toggle-item' src='/assets/img/faqs/toggle.svg'/>")
    });

    // Toggle button for individual item
    $(".item h3").on("click", function() {
        $(this).parent().find(".answer").toggle();
        $(this).find("img.toggle-item").toggleClass("rotate");
        var container = $(this).parent().parent();
        if(!container.find(".answer").is(":visible")) {
            container.find(".toggle").text('Expand all');
        } else {
            container.find(".toggle").text('Collapse all');
        }
    });

    if($("aside li").length) {
         // Mobile nav jumplinks
        new ScrollMagic.Scene({triggerElement: "#main", offset: 0})
            .on('enter leave', () => {
                if($(window).width() < 768) {
                    $("aside, .overlay").hide();
                }
            })
            .setClassToggle("#mobile-nav", "active-scroll")
            .setPin("#mobile-nav")
            .addTo(controller);

        // Remove sidebar at end of page
        new ScrollMagic.Scene({triggerElement: "footer", offset: -80})
            .setClassToggle("#mobile-nav", "hide")
            .addTo(controller);
    }
   
    // Toggle Jumplink menu on mobile
    $("#mobile-nav").on("click", function() {
        $(this).toggleClass("opened");
        $("aside").toggle();
        $(".overlay").toggle();
    });

    $(".overlay").on("click", function() {
        $(this).toggle();
        $("#mobile-nav").removeClass("opened");
        $("aside").hide();
    })
});



